import './App.css';
import MindARViewer from './mindar-viewer';
import UI from './UI'

function App() {
  

  return (
    <div className="App">
      <MindARViewer/>    
      <UI/>
    </div>
  );
}

export default App;
