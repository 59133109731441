import React from 'react';
import './UI.css';

const UI = () => {

    return (
       <>
        <div></div>
       </>

    )
}

export default UI;