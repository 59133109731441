import React, { useRef } from 'react';
import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';

const MindARViewer = () => {
    const AFRAME = window.AFRAME;
    const sceneRef = useRef(null);

    // Verifing if find or not the target
    AFRAME.registerComponent('mytarget', {
      init: function () {
        this.el.addEventListener('targetFound', event => {
          console.log("target found");
         
            setTimeout(() => {
              Update(() => {
                setTimeout(() => {  
                }, 300);
              });
            }, 300);
          
        });
        this.el.addEventListener('targetLost', event => {
          

        });
      }
    });

    const Update = () => {
      // Variables
      const buildingButton = document.querySelector('#buildingButton');
      const blueprintButton = document.querySelector('#blueprintButton');   
      //const infoButton = document.querySelector('#infoButton');  

      const buildingMesh = document.querySelector('#buildingMesh');
      const blueprintMesh = document.querySelector('#blueprintMesh');   
     
      //START
     

      //UPDATE

      buildingButton.addEventListener("click", event => {
            console.log("building");
            buildingMesh.setAttribute("visible", true);
            blueprintMesh.setAttribute("visible", false);
      });

      blueprintButton.addEventListener("click", event => {     
            console.log("blueprint"); 
            buildingMesh.setAttribute("visible", false);
            blueprintMesh.setAttribute("visible", true);
      });

}
    
    
  return (
    <a-scene ref={sceneRef} 
    mindar-image="imageTargetSrc: /targets.mind; filterMinCF: 0.0001; filterBeta: 0.1; warmupTolerance: 1; missTolerance: 1;" 
    color-space="sRGB" 
    renderer="colorManagement: true, physicallyCorrectLights, logarithmicDepthBuffer" 
    xr-mode-ui="enabled: false" 
    loading-screen="dotsColor: #205a5a; backgroundColor: #e5dcca;"
    device-orientation-permission-ui="enabled: false">
      <a-camera   position="0 0 0" 
                  look-controls="enabled: false"
                  cursor="fuse: false; rayOrigin: mouse;" 
                  /* eslint-disable-next-line */
                  raycaster="far: ${customFields.libVersion}; objects: .clickable"
      />

      <a-assets>
            <img id="ground" src="/assets/img/Card.jpg" alt="Ground" crossOrigin="anonymous"/>

            <img id="blueprintBtn" src="/assets/img/blueprintButton.png" alt="NextButton" crossOrigin="anonymous" />
            <img id="buildingBtn" src="/assets/img/buildingButton.png" alt="PreviousButton" crossOrigin="anonymous" />
            <img id="infoBtn" src="/assets/img/infoButton.png" alt="WebsiteButton" crossOrigin="anonymous"/>
            <img id="BG" src="/assets/img/BG.png" alt="Background" crossOrigin="anonymous" />

            <a-asset-item id="building" src="/assets/mesh/building.gltf"/>
            <a-asset-item id="control" src="/assets/mesh/control.gltf"/>
            <a-asset-item id="plantaBaixa" src="/assets/mesh/MainMesh.gltf"/>
      </a-assets>
      

      <a-entity id="mytarget" mytarget="" mindar-image-target="targetIndex: 0" >
            <a-entity cursor="rayOrigin:mouse"/>
            <a-plane  src="#ground"            id="cardID"         position="0 0 -0.3"       height="2"   width="1.1"    rotation="0 0 0"/>
            <a-image  src="#BG"                id="background"    position="0.9 -0.0 0.49" height="1" width="1" rotation="137 90 0" />
            <a-image  src="#buildingBtn"       id="buildingButton" class="clickable" position="-0.02 -1 -0.1"      height="0.25" width="0.25"  rotation="0 0 0" transparent="true" alpha-test="0.5" /> 
            <a-image  src="#blueprintBtn"      id="blueprintButton"class="clickable" position="-0.34 -1 -0.1"   height="0.25" width="0.25"  rotation="0 0 0" transparent="true" alpha-test="0.5" />  
            <a-image  src="#infoBtn"           id="infoButton"     class="clickable" position="0.315 -1 -0.1"    height="0.25" width="0.25"  rotation="0 0 -0" transparent="true" alpha-test="0.5"/> 
            

            <a-gltf-model rotation="90 0 0" position="-0 -1 -0.2" scale="0.37 0.37 0.37" src="#control" id="control" visible="true"/>
            <a-gltf-model rotation="0 90 90" position="0.25 0.1 -0.23" scale="0.19 0.19 0.19" src="#plantaBaixa" id="blueprintMesh" visible="false"/>
            <a-gltf-model rotation="90 0 0" position="0 0.3 0.3" scale="0.035 0.035 0.035" src="#building" id="buildingMesh" visible="true"/>

            
      </a-entity>
    </a-scene>
  )
}
export default MindARViewer